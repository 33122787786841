.footer {
  margin-top: 150px;
  margin-bottom: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: $font-family-extra;

  @include mq($until: xs) {
    margin-bottom: 50px;
  }

  &__nav {
    @include mq($until: md) {
      display: none;
    }
  }

  &__logo {
    @include mq($until: xs) {
      max-width: 100px;
    }
  }
}


.info {
  display: flex;
  align-items: flex-end;

  &__container {
    height: 60%;
    width: 100%;
    display: grid;
    background-color: $color-main;
    grid-template-columns: 1fr 2fr;

    @include mq($until: md) {
      grid-template-columns: 1fr;
      grid-template-rows: min-content;
      height: auto;
      padding-bottom: 50px;
    }
    @include mq($until: xs) {
      padding-bottom: 30px;
    }
  }

  &__contact {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: repeat(3, max-content);
    align-items: center;
    margin: auto 0;
    column-gap: 20px;
    row-gap: 20px;

    @include mq($from: sm, $until: md) {
      grid-row: 2 / span 1;
      grid-template-columns: 28px auto 28px auto 28px auto;
      grid-template-rows: auto;
      column-gap: 15px;
      padding-top: 15px;
    }

  }

  &__icon {
    align-self: flex-start;
  }

  &__map {
    border: 5px solid white;
    border-radius: 20px;
    overflow: hidden;
    transform: translateY(-10%);
    box-shadow: 0 3px 5px 3px rgba(0, 0, 0, 0.25);
    height: 350px;

    @include mq($from: xl) {
      height: 500px;
    }

    @include mq($until: md) {
      grid-row: 1 / span 1;
    }

    @include mq($until: xs) {
      height: 250px;
    }
  }

  &__text {
    color: white;
    font-style: italic;
    font-size: rem(14px);

    p {
      color: inherit;
    }

    a {
      color: white;
      border-bottom: 1px solid transparent;
      transition: border-color .3s;

      &:hover {
        border-color: white;
      }
    }
  }

  .svg-icon {
    width: 28px;
    fill: white;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: start;
    align-items: flex-start;
  }
}

.footer-small {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  @include mq($until: xs) {
    flex-direction: column;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  span, a, div {
    color: $color-font-dark;
    font-size: rem(13px);
  }

  a:hover {
    color: $color-main;
  }

  &__copyright {
    @include mq($until: xs) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  &__realization {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    span {
      transition: color .3s ease-in-out;
    }

    &:hover span {
      color: $color-main;
    }

    img {
      margin-left: 15px;
      @include mq($until: xs) {
        margin-left: 5px;
      }
    }
  }
}