.calculator {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;

  @include mq($from: xl) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include mq($until: sm){
    grid-template-columns: 1fr;
    grid-column-gap: 0px;
  }


  &__fence{
    grid-column: 1 / span 2;
    @include mq($until: sm){
      grid-column: 1 / span 1;
    }
  }

  &__params{
    grid-column: 1 / span 1;
  }

  &__results{
    grid-column: 2 / span 1;
    h2{
      color: $color-main;
    }
    p{
      font-weight: $fw-medium;
    }
    @include mq($until: sm){
      grid-column: 1 / span 1;
    }
  }

  &__contact{
    grid-column: 1 / span 2;

    @include mq($from: xl) {
      grid-column: 3 / span 1;
      grid-row: 1 / span 2;
    }
  }

  &__header {
    h2 {
      font-size: rem(18px);
      margin-bottom: 10px;
    }
  }

  &__box {
    background-color: whitesmoke;
    border: solid 1px transparent;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    &.active{
      border-color: gainsboro;
    }
  }

  &__hidebox{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    opacity: 0.8;
  }

  &__form-item {
    margin-bottom: 20px;

    label {
      margin-left: 5px;
      font-weight: $fw-medium;
      @include mq($until: xs){
        margin-left: 0;
        display: block;
      }
    }
  }

  &__comment {
    font-size: rem(12px);
    font-style: italic;
    margin-top: 5px;
  }

  &__final-price{
    color: $color-main;
  }

  &__summary{
    font-size: rem(16px);
  }
}

.form-input {
  input, select {
    border: solid 1px gainsboro;
    border-radius: 5px;
    outline: none;
    padding: 10px 5px;
    width: 200px;
    font-size: rem(14px);
    font-weight: $fw-medium;
    color: $color-font-dark;
  }
  input::placeholder{
    color: #e0e0e0;
    font-weight: $fw-medium;
  }
  select{
    cursor: pointer;
  }
  option{
    font-size: rem(12px);
  }
}