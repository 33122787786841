.hero {
  display: flex;
  align-items: center;
  background-image: url('../../images/top-gaton.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100vh;

  @include mq($until: md){
    height: 65vw;
  }

  @include mq($until: xs){
    height: 50vw;
    margin-top: 20px;
  }

  &__title {
    color: $color-font-dark;
    margin-bottom: 10px;
    font-weight: $fw-regular;
    line-height: 1.2;
    font-size: rem(34px);
    letter-spacing: 1px;
    b{
      display: block;
    }
    @include mq($until: md){
      font-size: rem(28px);
    }
    @include mq($until: xs){
      text-align: center;
    }
  }

  &__box {
    position: relative;
    top: -50px;
    @include mq($until: sm) {
      position: static;
      padding: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: whitesmoke;
    }
    @include mq($until: xs){
      padding: 15px;
      flex-direction: column;
    }
  }
}
.products {
  margin-top: 100px;
  margin-bottom: 100px;

  @include mq($until: md){
    margin-top: 50px;
    margin-bottom: 50px;
  }

  @include mq($until: xs){
    margin-top: 30px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mq($until: xs){
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  &__offer{
    @include mq($until: md){
      width: 50%;
    }
    @include mq($until: xs){
      width: 100%;
    }
  }

  &__title {
    color: $color-main;
    font-weight: $fw-regular;
    line-height: 1.2;
    font-size: rem(30px);
    letter-spacing: 1px;

    @include mq($until: md){
      font-size: rem(24px);
    }

    b {
      display: block;
    }
  }

  &__warranty {
    @include mq($until: md){
      width: 50%;
    }
    @include mq($until: xs){
      width: 100%;
    }
    p {
      font-style: italic;
      font-size: rem(14px);
    }
  }
}

.bold {
  font-size: 1.5em;
  font-weight: $fw-bold;
  color: $color-main;
  font-family: Blinker, sans-serif;
}

.valuation {
  background-image: url('../../images/sekcja3.jpg');
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  height: 55vw;
  max-height: 100vh;

  //@include mq($until: xl) {
  //  height: 50vw;
  //}

  &__box {
    background-color: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    padding: 30px 30px 0 30px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include mq($until: xs){
      padding: 15px 15px 0 15px;
    }

    h2{
      color: $color-font-dark;
      font-size: rem(26px);
      font-weight: $fw-semi-bold;
    }

    p{
      font-size: rem(14px);
      font-weight: $fw-regular;
      max-width: 400px;
    }

    img{
      opacity: 0.1;
      margin-left: 30px;
      @include mq($until: xs){
        max-width: 80px;
      }
    }

  }

  &__button-container {
    display: flex;
    margin-top: 30px;
  }

  &__button {
    align-self: flex-start;
    margin-right: 50px;

    @include mq($until: sm) {
      margin-right: 0;
    }
  }
}

.features{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 150px;
  flex-wrap: wrap;
  @include mq($until: md){
    margin-top: 50px;
  }
  &__left{
    width: 60%;
    @include mq($until: md){
      width: 100%;
    }
  }
  &__header{
    font-weight: $fw-semi-bold;
    font-size: rem(26px);
    color: $color-main;
    margin-bottom: 25px;
  }
  &__list{
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: rgb(245,245,245);
    border: 1px solid rgb(220,220,220);
    padding-right: 30%;
    @include mq($until: sm){
      padding-right: 30px;
    }
  }
  &__item{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 30px;
    &:last-of-type{
      margin-bottom: 0;
    }
    p{
      font-size: rem(14px);
      font-weight: $fw-light;
      line-height: 1.6;
      width: 100%;
    }
  }
  &__icon{
    margin-right: 30px;
    margin-top: -10px;
    width: 42px;
  }

  &__right{
    width: 40%;
    height: 100%;
    background: linear-gradient(to bottom, $color-main 0%, $color-main 70%, transparent 70%, transparent 100%);
    @include mq($until: md){
      width: 100%;
      background: $color-main;
      margin-top: 125px;
    }
    @include mq($until: sm){
      margin-top: 100px;
    }
  }

  &__box{
    @include mq($until: md){
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 200px;
      position: relative;
    }
    @include mq($until: sm){
      max-height: 150px;
    }
    img{
      position: relative;
      top: -15px;
      left: -45%;
      max-width: 90%;
      @include mq($until: md){
        height: 400px;
        width: auto;
        position: static;
      }
      @include mq($until: sm){
        height: 300px;
      }
    }
  }
}

