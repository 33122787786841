.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 35px;
  font-family: $font-family-extra;
  position: relative;

  &--absolute{
    position: absolute;
    width: 100%;
    @include mq($until: sm){
      padding-top: 15px;
    }
  }

  &__logo{
    @include mq($until: md){
      max-width: 130px;
    }
    @include mq($until: xs){
        max-width: 100px;
    }
  }

  &--fixed {
    position: fixed;
    width: 100%;
    transition: background-color .3s ease-in-out;
    z-index: 9;

    &.sticky {
      background-color: #FFFFFF;
      box-shadow: 0 0 30px rgba(0,0,0,0.1);
    }
  }

  &__nav{
    max-width: calc(100% - 200px);
  }

  &__menuList {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__menuItem {
    margin-left: 3vw;
    padding: 0;

    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: $color-font-dark;
    font-size: rem(16px);
    font-weight: $fw-semi-bold;
    position: relative;
    display: inline-block;
    letter-spacing: 0.5px;

    &::before{
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: $color-main;
      opacity: 0;
      transition: opacity .3s ease-in-out;
    }

    &:active, &--active, &--open, &:hover {
      color: $color-font-dark;
      &::before{
        opacity: 1;
      }
    }
  }

  &__calc{
    position: absolute;
    top: 0;
    right: 0;
    background-color: $color-main;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 8px 8px 8px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: background-color .3s ease-in-out;
    @include mq($until: md){
      position: static;
      justify-self: flex-end;
      margin-left: auto;
      border-radius: 5px;
    }
    @include mq($until: xs){
      display: none;
    }

    &:hover{
      background-color: $color-extra;
    }
    span{
      color: $color-font-light;
      margin-left: 10px;
      font-family: $font-family-extra;
      font-size: rem(13px);
    }
  }
}