.button {
  color: $color-font-light;
  padding: 15px 30px;
  transition: background-color .3s ease-in-out;
  background-color: $color-main;
  border-radius: 5px;
  display: inline-block;
  line-height: 1;
  text-align: center;
  //align-self: flex-start;
  cursor: pointer;
  font-weight: $fw-semi-bold;
  font-size: rem(14px);
  border: none;

  &:hover {
    background-color: $color-extra;
    color: $color-font-light;
  }

  &--secondary{
    background-color: $color-extra;

    &:hover {
      background-color: $color-main;
      color: $color-font-light;
    }
  }
}