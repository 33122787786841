.subsite{
  &__header{
    background-color: $color-main;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 30px;
    margin-bottom: 50px;
    h1{
      font-size: rem(32px);
      color: $color-font-light;
      margin-bottom: 0;
      text-align: center;
      font-weight: $fw-semi-bold;
    }
  }
}