// =========================
// Fonts
// =========================

@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  src: url('../../fonts/Montserrat-Light.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: url('../../fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: url('../../fonts/Montserrat-Medium.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: url('../../fonts/Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: 'Blinker';
  font-weight: 400;
  src: url('../../fonts/Blinker-Regular.ttf');
}

@font-face {
  font-family: 'Blinker';
  font-weight: 600;
  src: url('../../fonts/Blinker-SemiBold.ttf');
}

@font-face {
  font-family: 'Blinker';
  font-weight: 700;
  src: url('../../fonts/Blinker-Bold.ttf');
}