.product-cards {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 40px;
  background-color: rgb(245, 245, 245);
  border: 1px solid rgb(220, 220, 220);
  margin-top: 150px;

  @include mq($until: lg){
    grid-gap: 20px;
  }

  @include mq($until: md){
    grid-template-columns: 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 80px;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 50px;
  }

  @include mq($until: sm) {
    margin-top: 20px;
  }

  &--calc{
    padding: 0px;
    margin-top: 115px;
    grid-template-columns: repeat(4, 1fr);
    @include mq($until: md){
      margin-top: 90px;
    }
    @include mq($until: sm){
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 30px;
      grid-row-gap: 100px;
      padding-top: 0px;
      padding-bottom: 10px;
      margin-top: 80px;
    }
  }

}

.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 50px;
  position: relative;

  &:hover {
    .product-card__name {
      color: $color-main;
    }

    .product-card__button {
      transform: translateY(50%) scaleY(1);
      background-color: $color-main;
      @include mq($until: md){
        background-color: $color-extra;
      }
    }
  }

  @include mq($until: md){
    display: grid;
    grid-template-columns: 160px auto;
    grid-template-rows: repeat(3, auto);
    grid-gap: 15px;
    padding-bottom: 0;
  }

  @include mq($until: xs){
    grid-template-columns: 130px auto;
  }

  &--calc{
    padding-bottom: 10px;
    cursor: pointer;
    &:hover, &.active{
      .product-card__name {
        color: $color-main;
      }
      .product-card__image-container{
        box-shadow: 0 0 0 5px $color-main;
      }
    }
    @include mq($until: md){
      display: flex;
    }
  }

  &__image-container {
    position: relative;
    overflow: hidden;
    width: 125px;
    padding-top: 125px;
    transform: rotate(45deg);
    border-radius: 20px;
    box-shadow: 0 0 0 5px white;
    margin-top: -67px;
    margin-bottom: 20%;
    transition: box-shadow .3s ease-in-out;

    @include mq($until: md){
      grid-row: 1 / span 3;
      margin-top: 0;
      margin-bottom: 0;
    }

    @include mq($until: xs) {
      width: 100px;
      padding-top: 100px;
      margin-left: 10px;
    }

    &--calc{
      @include mq($until: md){
        width: 100px;
        padding-top: 100px;
        margin-left: 0;
        margin-top: -50px;
        margin-bottom: 10%;
      }
      @include mq($until: sm){
        margin-bottom: 5%;
      }
    }
  }

  &__image {
    position: absolute;
    top: -20%;
    right: -20%;
    bottom: -20%;
    left: -20%;
    transform: rotate(-45deg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  &__name {
    margin-bottom: 15px;
    transition: color .3s;
    font-family: $font-family-extra;
    letter-spacing: .5px;
    font-weight: $fw-semi-bold;
    color: $color-font-dark;
    font-size: rem(18px);
    text-align: center;
    @include mq($until: md){
      margin-bottom: 0;
    }
    &--calc{
      @include mq($until: md){
        font-size: rem(14px);
      }
      @include mq($until: sm){
        font-size: rem(16px);
      }
    }
  }

  &__info {
    text-align: center;
    font-size: rem(14px);
    @include mq($until: lg){
      font-size: rem(12px);
    }
    @include mq($until: md){
      text-align: left;
    }
  }

  &__button {
    @extend .button;
    position: absolute;
    bottom: 0;
    transform: translateY(50%) scaleY(0);
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    transition: transform .2s ease-in-out;
    @include mq($until: md){
      position: relative;
      transform: unset !important;
      width: 130px;
      transition: background-color .3s ease-in-out;
      padding: 10px;
      font-size: rem(13px);
    }
  }
}

